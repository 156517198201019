import { IToken } from '@/model/login/IToken';
import { Icon } from '@/model/util/Icon';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { Axios, AxiosError, AxiosResponse } from 'axios';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'Login',
		component: () => import('../views/Login.vue'),
	},
	{
		path: '/dashboard',
		component: () => import('../views/MainBase.vue'),
		children: [
			{
				path: 'Inicio',
				name: 'Menu',
				component: () =>
					import('@/views/main/MainInicio.vue'),
				meta: {
					secure: true,
					resource: 'inicio',
					parent: 'Inicio',
				},
			},
			{
						path: 'dashboard',
						name: 'Dashboards',
						component: () =>
							import('../views/main/MainMenu.vue'),
						meta: {
							secure: true,
							resource: 'Dashboard',
							parent: 'dashboard',
						},
					},
					{
						path: 'campania/:portfolio',
						component: () =>
							import(
								'../views/portfolio/IndexPortfolio.vue'
							),
						children: [
							{
								name: 'MainPortfolio',
								path: '',
								component: () =>
									import(
										'../views/portfolio/MainPortfolio.vue'
									),
									meta: {
										secure: true,
										resource: 'Dashboard',
										parent: 'dashboard',
									},
							},
							{
								name: 'Agent',
								path: ':agent',
								component: () =>
									import(
										'../views/agent/MainAgent.vue'
								),
								meta: {
									secure: true,
									resource: 'Dashboard',
									parent: 'dashboard',
								},
							},
					],
				},
				{
					path: 'habilidadesBlandas',
					name: 'MainHabilidadesBlandas',
					component: () =>
						import(
							'../views/habilidadesBlandas/MainHabilidadesBlandas.vue'
						),
						meta: {
							secure: true,
							resource: 'habilidadesBlandas',
							parent: 'habilidades',
						},
				},
				{
					path: 'detailHabilidades',
					name: 'MainDetailHabilidadesBlandas',
					component: () =>
						import(
							'../views/habilidadesBlandas/MainDetailHabilidadesBlandas.vue'
						),
						meta: {
							secure: true,
							resource: 'detailHabilidades',
							parent: 'detailHabilidades',
						},
				},

			{
				path: 'listaAgentes',
				name: 'MainListAgents',
				component: () =>
					import(
						'../views/agentManagement/MainListAgents.vue'
					),
					meta: {
						secure: true,
						resource: 'listaAgentes',
						parent: 'listAgents',
					},
			},
			{
				path: 'dashboardAgente',
				name: 'MainDashAgentes',
				component: () =>
					import(
						'../views/agentManagement/MainDashAgentes.vue'
					),
					meta: {
						secure: true,
						resource: 'dashboardAgente',
						parent: 'DashAgentes',
					},
			},
			/*{
				path: 'administracion',
				name: 'MainAdministration',
				component: () =>
					import(
						'@/views/administration/MainAdministration2.vue'
					),
				meta: {
					secure: true,
					resource: 'Carga de datos',
					parent: 'administracion',
				},
			},*/
			{
				path: 'reportes',
				name: 'MainReports',
				component: () =>
					import(
						'../views/reports/MainReports.vue'
					),
					meta: {
						secure: true,
						resource: 'reportes',
						parent: 'reports',
					},
			},
			{
				path: 'tag',
				name: 'MainReportsTags',
				component: () =>
					import(
						'../views/reports/MainReportsTags.vue'
					),
					meta: {
						secure: true,
						resource: 'reportsTag',
						parent: 'tags',
					},
			},
			{
				path: 'reportsPonderador',
				name: 'MainRepPonderadores',
				component: () =>
					import(
						'../views/reports/MainRepPonderadores.vue'
					),
					meta: {
						secure: true,
						resource: 'ponderadores',
						parent: 'reportsPonderadores',
					},
			},
			{
				path: 'reportMotivoMora',
				name: 'MainReportsMotMora',
				component: () =>
					import(
						'../views/reports/MainReportsMotMora.vue'
					),
					meta: {
						secure: true,
						resource: 'repMotivoMora',
						parent: 'reportMotivoMora',
					},
			},
			{
				path: 'configTagAudio',
				name: 'MainTagAudios',
				component: () =>
					import(
						'../views/motivos/MainTagAudios.vue'
					),
				meta: {
					secure: true,
					resource: 'configTagsAudios',
					parent: 'configTagAudio',
				},
			},
			{
				path: 'audiosTags',
				name: 'MainTags',
				component: () =>
					import(
						'../views/motivos/MainTags.vue'
					),
				meta: {
					secure: true,
					resource: 'audioTag',
					parent: 'audiosTags',
				},
			},
			{
				path: 'motivos',
				name: 'MainMotivos',
				component: () =>
					import(
						'../views/motivos/MainMotivos.vue'
					),
				meta: {
					secure: true,
					resource: 'motivo',
					parent: 'motivos',
				},
			},
			{
				path: 'evaluacion',
				component: () =>
					import(
						'../views/evaluation/IndexEvaluation.vue'
					),
				children: [
					{
						path: '',
						name: 'MainEvaluation',
						component: () =>
							import(
								'../views/evaluation/MainEvaluation.vue'
							),
						meta: {
							secure: true,
							resource: 'Ficha',
							parent: 'evaluacion',
						},
					},
					{
						path: 'resultado',
						name: 'ResultEvaluation',
						component: () =>
							import(
								'../views/evaluation/ResultEvaluation.vue'
							),
						meta: {
							secure: true,
							resource: 'Ficha',
							parent: 'evaluacion',
						},
					},
				],
			},
			{
				path: 'transcripcion',
				component: () =>
					import(
						'../views/transcription/IndexTranscription.vue'
					),
				children: [
					{
						path: '',
						name: 'MainTranscription',
						component: () =>
							import(
								'../views/transcription/MainTranscription.vue'
							),
						meta: {
							secure: true,
							resource: 'Transcripciones',
							parent: 'transcripcion',
						},
					},
					{
						path: 'seleccion',
						name: 'SelectTranscription',
						component: () =>
							import(
								'../views/transcription/SelectTranscription.vue'
							),
						meta: {
							secure: true,
							resource: 'Transcripciones',
							parent: 'transcripcion',
						},
					},
					{
						path: 'transcripcion',
						name: 'Transcription',
						component: () =>
							import(
								'../views/transcription/Transcription.vue'
							),
						meta: {
							secure: true,
							resource: 'Transcripciones',
							parent: 'transcripcion',
						},
					},
				],
			},
			{
				path: 'script',
				name: 'MainScripts',
				component: () =>
					import('../views/scripts/MainScripts.vue'),
				meta: {
					secure: true,
					// resource: 'Campañas/scripts',
					resource: 'Usuarios',
					parent: 'script',
				},
			},
			{
				path: 'scriptDataTable',
				name: 'MainScriptsTable',
				component: () =>
					import('../views/scripts/MainScriptsTable.vue'),
				meta: {
					secure: true,
					// resource: 'scriptsTable',
					resource: 'Usuarios',
					parent: 'scriptDataTable',
				},
			},
			{
				path: 'usuario',
				name: 'MainUser',
				component: () =>
					import('../views/user/MainUser.vue'),
				meta: {
					secure: true,
					resource: 'Usuarios',
					parent: 'usuario',
				},
			},
			{
				path: 'library',
				name: 'MainAudioLibrary',
				component: () =>
					import(
						'../views/audioLibrary/MainAudioLibrary.vue'
					),
				meta: {
					secure: true,
					resource: 'library',
					parent: 'audioLibrary',
				},
			},
			/*{
				path: 'audioSearch',
				name: 'MainAudioSearch',
				component: () =>
					import(
						'../views/audioLibrary/MainAudioSearch.vue'
					),
				meta: {
					secure: true,
					resource: 'audioSearch',
					parent: 'audioLibrarySearch',
				},
			},*/
			// se agrega un indexLibrary y MainDetailLibrary para que al presionar una opcion de dataTable de MainAudioSearch abra una vista hijo de este
			{
			path: 'audioSearch/:audio',
			component: () =>
				import(
					'../views/audioLibrary/indexLibrary.vue'
				),
			children: [
				{
					path: '',
					name: 'MainAudioSearch',
					component: () =>
						import(
							'../views/audioLibrary/MainAudioSearch.vue'
						),
					meta: {
						secure: true,
						resource: 'audioSearch',
						parent: 'audioLibrarySearch',
					},
				},
				{
					name: 'detailLibrary',
					path: 'MainDetailLibrary',
					component: () =>
						import(
							'../views/audioLibrary/MainDetailLibrary.vue'
						),
						meta: {
							secure: true,
							resource: 'audioSearch',
							parent: 'audioLibrarySearch',
						},
					},
					{
						path: 'audioDetail',
						name: 'MainAudioDetail',
						component: () =>
							import(
								'../views/audioLibrary/MainAudioDetail.vue'
							),
						meta: {
							secure: true,
							resource: 'audioSearch',
							parent: 'audioLibrarySearch',
						},
					},
				],
			},
		],
	},
];

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
});
/**
 meta: {
		secure: true, se revisa si se debe proteger
		resource: 'Campañas/scripts', nombre del recuros a consultar
		parent: "script" para la vista :v
},
 */
router.beforeEach(async (to, from, next) => {
	if (to.meta!.secure) {
		try {
			const data = `grant_type=urn:ietf:params:oauth:grant-type:uma-ticket&audience=${process.env.VUE_APP_KEYCLOAK_AUDIENCE}&permission=${
				to.meta!.resource
			}&scope=view`;
			const res: AxiosResponse<IToken> = await internet
				.newRequest()
				.post(
					`${process.env.VUE_APP_KEYCLOAK_URL}/auth/realms/${process.env.VUE_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
					data,
					{
						headers: {
							'Content-Type':
								'application/x-www-form-urlencoded',
						},
					}
				);
			if (res.status === 200) {
				next();
			}
		} catch (error) {
			Util.showMessage(
				"Sin permiso" as string, Icon.ERROR);

			next({
				name: from.name!,
			});
			console.log(error);
			window.location.href="https://alloagent.alloxentric.com/"

		}
	} else {
		next();
	}
});

export default router;
